<template>
  <div class="invoice">
    <div v-if="isEmployee" class="page-title">{{ $t('navigation.invoice') }}</div>

    <base-dialog ref="deleteDialog" :title="$t('invoice.deletion_confirmation')" />

    <info-modal
      v-model="paymentModal"
      title="Editing invoices with automatically filled in payment type is prohibited"
      @close="closePaymentModal"
    >
      <v-btn color="primary" block @click="closePaymentModal">{{ $t('invoice.back_to_invoices') }}</v-btn>
    </info-modal>

    <info-modal v-model="modal.show" :title="modal.message" @close="closeModal">
      <v-btn color="primary" block @click="closeModal">{{ $t('invoice.back_to_invoices') }}</v-btn>
    </info-modal>

    <invoices-settings-modal ref="settingsModal" :can-update="canUpdateInvoices" />
    <invoice-table-settings
      ref="tableSettingsModal"
      :limit="invoicesLimit"
      :invoice-table-configs="invoiceTableConfigs"
      :access-to-rooms="accessToRooms"
      @update:limit="setLimit"
      @update:change-config="changeConfig"
      @apply-config="debouceFetch"
    />

    <entity-status-list v-if="isEmployee" :statuses="entityStatuses" class="mb-6">
      <invoices-menu
        v-if="!media.isMobile || isDataMissing"
        :can-update-invoices="canUpdateInvoices"
        :import-status="importStatus.status"
        :status="status"
        :payment-types="paymentTypes"
        @issue-invoice="issueInvoice"
        @export="exportInvoices"
        @import="importInvoices"
        @send-reminder="sendReminder"
        @issue-recurrence-invoice="issueRecurrenceInvoice"
        @export-accounting-data="exportAccountingData"
        @open-settings="openSettings"
        @open-table-settings="openTableSettings"
        @save-payment-type="savePaymentType"
      />
    </entity-status-list>

    <div v-else class="d-flex align-center justify-space-between">
      <h1 class="text-h5 text-md-h4 font-weight-bold">{{ $t('invoice.invoices') }}</h1>
      <v-btn large color="primary" @click="toggleUnpaid">
        {{ togglePaidStatusText }}
      </v-btn>
    </div>

    <v-row v-if="!isRecurrence" dense>
      <v-col v-for="head in summaryInfoHeaders" :key="head.value" cols="6" sm="4" lg="3">
        <invoice-metric-card
          :loading="metricIsLoading"
          :code-language="selectedLanguage.code"
          :label="$t(head.label)"
          :amount="summaryInfo[head.value]?.amount"
          :invoices-count="summaryInfo[head.value]?.count"
          :currency="summaryInfo.currency"
        />
      </v-col>
    </v-row>

    <template v-if="media.isMobile">
      <mobile-sort
        class="mt-2 mb-0 mb-md-6"
        :sort-list="sortedInvoicesHeaders"
        :sort-by="activeHeader"
        :options.sync="options"
        :reverse="canDisplayMobileMenu"
      >
        <invoices-menu
          v-if="canDisplayMobileMenu"
          :can-update-invoices="canUpdateInvoices"
          :import-status="importStatus.status"
          :status="status"
          :payment-types="paymentTypes"
          @issue-invoice="issueInvoice"
          @export="exportInvoices"
          @import="importInvoices"
          @send-reminder="sendReminder"
          @issue-recurrence-invoice="issueRecurrenceInvoice"
          @export-accounting-data="exportAccountingData"
          @open-settings="openSettings"
          @open-table-settings="openTableSettings"
        />
      </mobile-sort>

      <div class="d-flex align-center mb-2">
        <mobile-search-menu v-model="query" :placeholder="searchPlaceholder" single-line full-width hide-details />

        <invoices-modal-filters
          v-if="canDisplayInvoiceFilters"
          ref="modalFilters"
          v-model="filtersData"
          :status="status"
        />
      </div>
    </template>

    <div v-if="isLoading" class="d-flex align-center justify-center" style="min-height:200px">
      <v-progress-circular indeterminate color="primary" />
    </div>

    <invoices-missing-data
      v-else-if="isDataMissing"
      class="invoice__missing-data"
      :is-employee="isEmployee"
      :status="status"
    />

    <template v-else>
      <import-status
        v-if="isEmployee"
        class="text-left text-md-right mt-2 mb-1"
        :status="importStatus.status"
        :url="importStatus.url"
        :error-document-name="errorDocumentName"
        :get-status="getImportStatus"
        @show-modal="showModal($t('import.process'))"
      />

      <template v-if="media.isMobile">
        <invoices-mobile-list
          :items="invoices"
          :payment-types="paymentTypes"
          :is-employee="isEmployee"
          :status="status"
          :access-to-rooms="accessToRooms"
          :invoice-table-configs="invoiceTableConfigs"
          :code-language="selectedLanguage.code"
          @delete="deleteInvoice"
          @download-invoice="downloadInvoice"
          @close-overlay="closeOverlay"
          @save-payment-type="savePaymentType"
          @update-comment="updateComment"
        />
      </template>

      <template v-else>
        <div class="d-flex align-center">
          <!-- Временно убрал условие canDisplayInvoiceFilters -->
          <search-bar
            v-model="query"
            class="pt-3 mt-0"
            :class="{ 'mr-4': false }"
            :placeholder="searchPlaceholder"
            full-width
            hide-details
          />

          <invoices-modal-filters v-if="false" ref="modalFilters" v-model="filtersData" :status="status" />
        </div>

        <invoices-filter v-if="canDisplayInvoiceFilters" ref="filters" v-model="filtersData" :status="status" />

        <template v-if="!isLoading">
          <invoices-table
            v-model="invoiceHighlighting.selectedInvoices"
            class="mt-6"
            :is-employee="isEmployee"
            :invoices="invoices"
            :payment-types="paymentTypes"
            :loading="tableLoading"
            :options.sync="options"
            :headers="currentInvoicesHeaders"
            :server-items-length="invoicesLimit"
            :page-count="pageCount"
            :invoices-count="invoicesCount"
            :page-invoices-count="pageInvoicesCount"
            :is-recurrence="isRecurrence"
            :can-update="canUpdateInvoices"
            :status="status"
            :code-language="selectedLanguage.code"
            :top-scrollable="false"
            @global-select="setGlobalSelect"
            @download-invoice="downloadInvoice"
            @close-overlay="closeOverlay"
            @delete-invoice="deleteInvoice"
            @save-payment-type="savePaymentType"
            @update-comment="updateComment"
          />
        </template>

        <div v-else class="invoice__loader-wrapper">
          <v-progress-circular indeterminate class="invoice__loader" />
        </div>
      </template>

      <base-pagination v-if="isShowPagination" v-model="pageModel" :length="pageCount" class="mt-10" />
    </template>
  </div>
</template>

<script>
// node_modules
import { format, startOfMonth, endOfMonth } from 'date-fns';

// Components
import InvoiceMetricCard from '@/components/Invoices/InvoiceMetricCard.vue';
import BasePagination from '@/components/BasePagination.vue';
import InvoicesMenu from '@/components/Invoices/Menu.vue';
import InvoicesMobileList from '@/components/Invoices/MobileList.vue';
import MobileSort from '@/components/MobileSort/index.vue';
import ImportStatus from '@/components/ImportStatus.vue';
import InvoicesMissingData from '@/components/Invoices/MissingData.vue';
import SearchBar from '@/components/SearchBar.vue';
import MobileSearchMenu from '@/components/MobileSearchMenu.vue';
import EntityStatusList from '@/components/EntityStatusList.vue';
import InvoicesFilter from '@/components/Invoices/Filters.vue';
import InvoicesTable from '@/components/Invoices/Table.vue';
import InvoicesSettingsModal from '@/components/Invoices/SettingsModal.vue';
import InvoiceTableSettings from '@/components/Invoices/InvoiceTableSettings.vue';
import BaseDialog from '@/components/BaseDialog.vue';

// Constants
import { MANAGEMENT_COMPANY_EMPLOYEE } from '@/constants/roles';
import {
  INVOICES_CREATE,
  INVOICES_UNPAID,
  INVOICES_PAID,
  INVOICES,
  INVOICES_RECURRENCE,
  INVOICES_RECURRENCE_EDIT,
  INVOICES_RECURRENCE_CREATE,
} from '@/constants/routes';
import { IN_PROCESS, READY } from '@/constants/importStatuses';
import { RECURRENCE } from '@/constants/entityStatuses';
import {
  INVOICES_LIST,
  INVOICES_DETAILD,
  INVOICES_LIST_SORTING,
  INVOICES_LIST_FILTERING,
  IMPORT_INVOICES,
  EXPORT_INVOICES,
  EXPORT_ACCOUNTING_DATA,
} from '@/constants/analyticsActions';
import { INVOICES as INVOICES_SUBJECT } from '@/constants/subjects';
import { UPDATE } from '@/constants/actions';
import { DEFAULT_HEADERS, INVOICE_TABLE_CONFIGS, INVOICE_ITEM_PAGE_COUNT } from '@/constants/invoices';

// Services
import paymentsService from '@/services/payments';
import mediaService from '@/services/media';
import analyticsService from '@/services/analytics';
import notificationService from '@/services/notification';
import { loadStorageItem, saveStorageItem, removeStorageItem } from '@/services/storage';

// Utils
import { translateDate, translateInvoiceDate } from '@/utils/dateFormatting';
import { separateThouthands } from '@/utils/priceFormatting';
import { camelToSnake } from '@/utils/formatters';
import { debounce, retry } from '@/utils/delay';
import { download, getServiceType, isEqual } from '@/utils/common';
import { getBaseUrlWithoutApi } from '@/http/getBaseURL';
import { clone } from '@/utils/clone';
import { checkEmptyParams, extractParamsFromMultiSelectObject } from '@/utils/many';
import { flushPromises } from '@/utils/scheduler';

// Schemas
import { createModelData } from '@/schemas/createModelData';
import { fullNewSchema } from '@/schemas/invoiceFilter.schema';

// Http
import client from '@/http/client';

import { mapGetters } from 'vuex';
import { COMPANY_SECTIONS_RIGHTS } from '@/store/modules/user/types';

export default {
  name: 'Invoice',

  components: {
    InvoiceMetricCard,
    BasePagination,
    InvoicesMenu,
    InvoicesMobileList,
    ImportStatus,
    InvoicesMissingData,
    MobileSort,
    SearchBar,
    MobileSearchMenu,
    InvoicesFilter,
    EntityStatusList,
    InvoicesTable,
    InvoicesSettingsModal,
    InvoiceTableSettings,
    InvoicesModalFilters: () => import('@/components/Invoices/ModalFilters.vue'),
    InfoModal: () => import('@/components/InfoModal.vue'),
    BaseDialog,
  },

  inject: ['media'],

  translateDate,

  props: {
    page: { type: Number, default: 1 },
    status: { type: String, required: true },
  },

  data() {
    return {
      invoicesLimit: 10,
      invoicesCount: 0,
      invoices: [],
      isFirstLoad: true,
      summaryInfoHeaders: [
        {
          label: 'invoices.summary_total',
          value: 'planned',
        },
        {
          label: 'invoices.summary_paid',
          value: 'actual',
        },
        {
          label: 'invoices.summary_pending',
          value: 'debt',
        },
      ],
      summaryInfo: {},
      paymentTypes: [],
      tableLoading: false,
      metricIsLoading: false,
      isUnpaid: false,
      isLoading: false,
      importStatus: {},
      isDataMissing: false,
      sendMessagesAfterMounted: true,

      invoiceTableConfigs: [],

      invoiceHighlighting: {
        selectedInvoices: [],
        dialogIsOpen: false,
        globalSelect: false,
      },
      filtersData: {
        ...createModelData(fullNewSchema),
        serviceTypes: { all: false, exclude: [], include: [] },
      },

      filters: {
        invoiceDateAfter: undefined,
        invoiceDateBefore: undefined,
        paymentDateAfter: undefined,
        paymentDateBefore: undefined,
        projects: undefined,
        buildings: undefined,
        units: undefined,
        rooms: undefined,
        clients: undefined,
        paymentTypes: undefined,
        serviceTypes: { all: false, exclude: [], include: [] },
        paymentDeadline: null,
        paymentDeadlineAfter: undefined,
        paymentDeadlineBefore: undefined,
        paymentDeadlineExact: undefined,
        status: undefined,
      },

      modal: {
        show: false,
        message: '',
      },
      paymentModal: false,
      paymentModalOpen: false,
      query: '',

      options: { sortBy: '', sortDesc: null },

      errorDocumentName: 'invoices_errors.xlsx',
    };
  },

  computed: {
    ...mapGetters(['selectedLanguage']),

    canUpdateInvoices() {
      return this.$can(UPDATE, INVOICES_SUBJECT);
    },

    canDisplayInvoiceFilters() {
      return !this.isRecurrence && !this.isUnpaidStatus && this.isEmployee;
    },

    isFiltered() {
      // eslint-disable-next-line no-unused-vars
      return !Object.entries(this.filters).every(([_, value]) => !value);
    },

    isRecurrence() {
      return this.status === RECURRENCE;
    },

    isAllStatus() {
      return this.status === 'all';
    },

    isPaid() {
      return this.status === 'paid';
    },

    isUnpaidStatus() {
      return this.status === 'unpaid';
    },

    pageInvoicesCount() {
      return this.invoices.length;
    },

    entityStatuses() {
      return [
        {
          name: INVOICES,
          text: this.$t('invoice.all_status'),
        },
        {
          name: INVOICES_UNPAID,
          text: this.$t('invoice.unpaid_status'),
        },
        {
          name: INVOICES_PAID,
          text: this.$t('invoice.paid_status'),
        },
        {
          name: INVOICES_RECURRENCE,
          text: this.$t('invoice.recurrence'),
          tooltip: this.$t('invoice.recurrence_tab_tooltip'),
        },
      ];
    },

    togglePaidStatusText() {
      if (this.media.isMobile) {
        if (this.isUnpaid) {
          return this.$t('invoice.unpaid');
        }

        return this.$t('invoice.all');
      }

      if (this.isUnpaid) {
        return this.$t('invoice.show_all');
      }

      return this.$t('invoice.show_unpaid');
    },

    isEmployee() {
      return this.$store.state.user.role === MANAGEMENT_COMPANY_EMPLOYEE;
    },

    invoicesHeaders() {
      let headers;
      if (this.isEmployee) {
        if (this.isRecurrence) {
          headers = [
            {
              text: this.$t('invoice.employee_invoice_for'),
              value: 'clientName',
              width: '14%',
              sortValue: 'clientName',
            },
            {
              text: this.$t('invoice.project'),
              value: 'projectName',
              width: '12%',
              sortValue: 'project',
            },
            { text: this.$t('invoice.unit'), value: 'unitName', width: '10%', sortValue: 'unit' },
            { text: this.$t('invoice.room'), value: 'roomName', width: '10%', sortValue: 'room' },
            { text: this.$t('invoice.service_type'), width: '14%', value: 'serviceType' },
            {
              text: this.$t('invoice.first_invoice'),
              value: 'invoiceDateFirst',
              width: '11%',
            },
            {
              text: this.$t('invoice.last_invoice'),
              value: 'invoiceDateLast',
              width: '11%',
            },
            {
              text: this.$t('invoice.reccurence_period'),
              value: 'recurrencePeriod',
              width: '8%',
            },
            {
              text: this.$t('invoice.days_to_due'),
              value: 'numberDaysDueDate',
              width: '10%',
              sortable: false,
            },
            { text: this.$t('invoice.total_amount'), value: 'totalAmount', sortValue: 'amount', width: '16%' },
          ];
        } else {
          headers = [
            {
              text: this.$t('invoice.employee_invoice_for'),
              value: 'clientName',
              sortValue: 'clientName',
            },
            {
              text: this.$t('invoice.project'),
              value: 'projectName',
              sortValue: 'projectName',
            },
            { text: this.$t('invoice.unit'), value: 'unitName', width: '250px', sortValue: 'unitName' },
            { text: this.$t('invoice.room'), value: 'roomName', width: '250px', sortValue: 'roomName' },
            { text: this.$t('invoice.service_type'), value: 'serviceType' },
            { text: this.$t('invoice.invoice_number'), value: 'invoiceNumber', sortable: true },
            { text: this.$t('invoice.period'), value: 'period', sortValue: 'period' },
            {
              text: this.$t('invoice.invoice_date'),
              value: 'invoiceDate',
              sortValue: 'invoiceDate',
            },
            {
              text: this.$t('invoice.total_amount'),
              value: 'totalAmount',
              sortValue: 'amount',
            },
            { text: this.$t('invoice.due_date'), value: 'dueDate', sortValue: 'dueDate' },
            {
              text: this.$t('invoice.delay'),
              value: 'days',
              sortValue: 'days',
            },
            { text: this.$t('invoice.status'), value: 'status', sortValue: 'paymentDate' },
            {
              text: this.$t('invoice.payment_type'),
              value: 'paymentType',
              sortValue: 'paymentType',
              sortable: this.isPaid,
            },
          ];
        }

        if (this.isUnpaidStatus) {
          headers = headers.filter(item => item.value !== 'status');
        }
      } else {
        headers = [
          { text: this.$t('invoice.service_type'), width: '14%', value: 'serviceType' },
          { text: this.$t('invoice.company_name'), width: '14%', value: 'companyName' },
          { text: this.$t('invoice.unit'), width: '22%', value: 'unitName' },
          { text: this.$t('invoice.room'), width: '22%', value: 'roomName', sortable: false },
          { text: this.$t('invoice.period'), width: '15%', value: 'period' },
          { text: this.$t('invoice.invoice_date'), width: '14%', value: 'invoiceDate' },
          { text: this.$t('invoice.total_amount'), width: '11%', value: 'totalAmount', sortValue: 'amount' },
          { text: this.$t('invoice.paid'), width: '10%', value: 'status', sortable: false },
        ];
      }

      if (!this.accessToRooms) {
        headers = headers.filter(item => item.value !== 'roomName');
      }

      return headers;
    },

    sortedInvoicesHeaders() {
      return this.invoicesHeaders.slice().sort((a, b) => {
        const order1 = a.order || Number.MAX_VALUE;
        const order2 = b.order || Number.MAX_VALUE;

        return order1 - order2;
      });
    },

    currentInvoicesHeaders() {
      if (this.isAllStatus) {
        let headers = [...this.invoiceTableConfigs];

        if (!this.accessToRooms) {
          headers = headers?.filter(item => item.value !== 'roomName');
        }

        return headers?.filter(invoice => invoice.active).map(item => ({ ...item, text: this.$t(item.text) })) || [];
      }

      return this.sortedInvoicesHeaders;
    },

    searchPlaceholder() {
      return this.isEmployee ? this.$t('invoice.manger_invoice_search') : this.$t('invoice.client_invoice_search');
    },

    orderBy() {
      if (!this.options.sortBy || this.options.sortDesc === null) return undefined;

      const header = this.invoicesHeaders.find(invoiceHeader => invoiceHeader.value === this.options.sortBy);

      const value = camelToSnake(header?.sortValue || this.options.sortBy);

      if (this.options.sortDesc === null) return value;
      return this.options.sortDesc ? `-${value}` : value;
    },

    pageCount() {
      return Math.ceil(this.invoicesCount / this.invoicesLimit);
    },

    pageModel: {
      get() {
        return this.page;
      },

      set(value) {
        if (this.pageModel === value) return;
        const { query } = this.$route;
        this.$router.replace({ query: { ...query, page: value } });
      },
    },

    activeHeader() {
      return this.invoicesHeaders.find(
        header => header.value === this.options.sortBy || header?.sortValue === this.options.sortBy
      );
    },

    canDisplayMobileMenu() {
      return this.isEmployee && this.media.isMobile;
    },

    isShowPagination() {
      return this.pageCount && !this.isLoading && this.invoices.length;
    },

    ...mapGetters('user', {
      companySections: COMPANY_SECTIONS_RIGHTS,
    }),

    accessToRooms() {
      return this.isEmployee ? !!this.companySections?.find(section => section.name === 'rooms') : true;
    },

    normalizedFilters() {
      const extractParams = extractParamsFromMultiSelectObject;
      const filters = this.formatFilters(this.filtersData);
      const units = checkEmptyParams(filters?.units) ? undefined : extractParams(filters?.units);
      const rooms = checkEmptyParams(filters?.rooms) ? undefined : extractParams(filters?.rooms);
      return {
        ...filters,
        projects: checkEmptyParams(filters?.projects) ? undefined : extractParams(filters?.projects),
        buildings: checkEmptyParams(filters?.buildings) ? undefined : extractParams(filters?.buildings),
        units: rooms || units,
        clients: checkEmptyParams(filters?.clients) ? undefined : extractParams(filters?.clients),
        paymentTypes: checkEmptyParams(filters?.paymentTypes) ? undefined : extractParams(filters?.paymentTypes),
        serviceTypes: checkEmptyParams(filters?.serviceTypes) ? undefined : extractParams(filters?.serviceTypes),
        status: this.isAllStatus ? filters.status || this.status : this.status,
      };
    },

    queryParams() {
      return {
        limit: this.invoicesLimit,
        offset: this.invoicesLimit * (this.page - 1),
        unpaidOnly: this.isUnpaid,
        orderBy: this.orderBy,
        search: this.query || undefined,
        ...this.normalizedFilters,
      };
    },
  },

  watch: {
    status() {
      this.$nextTick(async () => {
        if (this.isDataMissing) {
          this.isLoading = true;
          this.isDataMissing = false;
        }

        this.filtersData = {
          ...createModelData(fullNewSchema),
          serviceTypes: { all: false, exclude: [], include: [] },
        };
        this.invoices = [];
      });

      this.invoiceHighlighting.selectedInvoices = [];
      this.invoiceHighlighting.globalSelect = false;
      this.query = '';
      this.options.sortBy = '';
      this.options.sortDesc = null;
    },

    queryParams: {
      handler(newValue, oldValue) {
        if (isEqual(newValue, oldValue) || this.isFirstLoad) {
          return;
        }

        this.invoices = [];
        this.debouceFetch();
      },
      deep: true,
    },

    'invoiceHighlighting.selectedInvoices': {
      handler(newValue) {
        if (newValue.length < this.pageInvoicesCount) {
          this.invoiceHighlighting.globalSelect = false;
        }
      },
      immediate: true,
    },

    invoicesCount() {
      const { query } = this.$route;

      if (this.page > this.pageCount && this.page !== 1 && this.pageCount > 0) {
        this.$router.replace({ query: { ...query, page: this.pageCount } });
      }
    },

    isUnpaid() {
      this.isDataMissing = false;
      // this.getInvoices();
    },

    async orderBy() {
      analyticsService.track(INVOICES_LIST_SORTING, analyticsService.createInvoicesListSortingPayload(this.orderBy));
    },

    query() {
      this.pageModel = 1;
    },
  },

  async mounted() {
    analyticsService.track(INVOICES_LIST);
    this.isLoading = true;

    const invoiceTableConfigs = loadStorageItem(INVOICE_TABLE_CONFIGS);
    this.invoicesLimit = parseInt(loadStorageItem(INVOICE_ITEM_PAGE_COUNT), 10) || 10;

    if (!invoiceTableConfigs) {
      saveStorageItem(INVOICE_TABLE_CONFIGS, JSON.stringify(DEFAULT_HEADERS));
      this.invoiceTableConfigs = Object.entries(DEFAULT_HEADERS).map(([key, value]) => ({
        value: key,
        ...value,
      }));
    } else {
      try {
        const savedConfigs = JSON.parse(invoiceTableConfigs);
        const configs = Object.entries(DEFAULT_HEADERS).map(([key, value]) => {
          if (!savedConfigs[key]) {
            savedConfigs[key] = value;
          }
          return {
            ...value,
            value: key,
            active: savedConfigs[key].active,
          };
        });

        this.invoiceTableConfigs = configs;
      } catch {
        removeStorageItem(INVOICE_TABLE_CONFIGS);
      }
    }

    this.getInvoices()
      .then(() => {
        if (!this.invoices.length && !this.query) {
          this.isDataMissing = true;
        }
      })
      .finally(() => {
        this.isFirstLoad = false;
      });

    if (this.isEmployee) {
      this.fetchImportStatus();
    }

    this.getPaymentTypes();
  },

  methods: {
    openSettings() {
      this.$refs.settingsModal.open();
    },

    openTableSettings() {
      this.$refs.tableSettingsModal.open();
    },

    setGlobalSelect(globalStatus) {
      this.invoiceHighlighting.globalSelect = globalStatus;
    },

    formatFilters(filters) {
      const formatedFilters = {};
      if (filters.projects) {
        formatedFilters.projects = clone(filters.projects);
      }

      if (filters.buildings) {
        formatedFilters.buildings = clone(filters.buildings);
      }

      if (filters.units) {
        formatedFilters.units = clone(filters.units);
      }

      if (filters.rooms) {
        formatedFilters.rooms = clone(filters.rooms);
      }

      if (filters.clients) {
        formatedFilters.clients = clone(filters.clients);
      }

      if (filters.paymentTypes) {
        formatedFilters.paymentTypes = clone(filters.paymentTypes);
      }

      if (filters.serviceTypes) {
        formatedFilters.serviceTypes = clone(filters.serviceTypes);
      }

      if (filters.status) {
        formatedFilters.status = filters.status.value;
      }

      if (filters.invoicePeriod) {
        const [after, before] = filters.invoicePeriod.split(' - ');
        formatedFilters.invoiceDateBefore = before;
        formatedFilters.invoiceDateAfter = after;
      } else {
        formatedFilters.invoiceDateBefore = undefined;
        formatedFilters.invoiceDateAfter = undefined;
      }

      if (filters.paymentPeriod) {
        const [after, before] = filters.paymentPeriod.split(' - ');
        formatedFilters.paymentDateAfter = after;
        formatedFilters.paymentDateBefore = before;
      } else {
        formatedFilters.paymentDateAfter = undefined;
        formatedFilters.paymentDateBefore = undefined;
      }

      if (filters.paymentDeadline) {
        if (filters.paymentDeadline.value === 'custom' && filters.paymentDeadlinePeriod) {
          const [
            paymentDeadlineAfter,
            paymentDeadlineBefore = paymentDeadlineAfter,
          ] = filters.paymentDeadlinePeriod.split(' - ');

          formatedFilters.paymentDeadlineAfter = paymentDeadlineAfter;
          formatedFilters.paymentDeadlineBefore = paymentDeadlineBefore;
        } else {
          const today = new Date();
          formatedFilters.paymentDeadlineAfter = undefined;
          formatedFilters.paymentDeadlineBefore = undefined;
          formatedFilters.paymentDeadlineExact = undefined;

          if (filters.paymentDeadline.value === 'month') {
            formatedFilters.paymentDeadlineAfter = format(startOfMonth(today), 'yyyy-MM-dd');
            formatedFilters.paymentDeadlineBefore = format(endOfMonth(today), 'yyyy-MM-dd');
          }

          if (filters.paymentDeadline.value === 'today') {
            formatedFilters.paymentDeadlineExact = format(today, 'yyyy-MM-dd');
          }

          if (filters.paymentDeadline.value === 'delay') {
            formatedFilters.paymentDeadlineBefore = format(today, 'yyyy-MM-dd');
          }
        }
      } else {
        formatedFilters.paymentDeadlineExact = undefined;
        formatedFilters.paymentDeadlineAfter = undefined;
        formatedFilters.paymentDeadlineBefore = undefined;
      }

      analyticsService.track(
        INVOICES_LIST_FILTERING,
        analyticsService.createInvoicesListFilteringPayload(formatedFilters)
      );

      return formatedFilters;
    },

    exportAccountingData() {
      analyticsService.track(EXPORT_ACCOUNTING_DATA);
      paymentsService.downloadReport('report.xlsx', { ...this.normalizedFilters });
    },

    sendReminder() {
      const send = this.invoiceHighlighting.globalSelect
        ? paymentsService.notificationAll
        : paymentsService.notification;

      send(this.invoiceHighlighting.selectedInvoices.map(invoice => invoice.id))
        .then(() => {
          this.showModal(this.$t('invoice.reminders_sent_successfully'));
          this.invoiceHighlighting.selectedInvoices = [];
          this.invoiceHighlighting.globalSelect = false;
        })
        .catch(() => {
          this.showModal(this.$t('invoice.failed_send_reminders'));
        });
    },

    closeOverlay(invoice) {
      const foundInvoice = this.invoices.find(localInvoice => localInvoice.id === invoice.id);

      foundInvoice.progress = 0;
      foundInvoice.isError = false;
      foundInvoice.isLoading = false;
    },

    downloadInvoice(invoice) {
      if (this.isRecurrence) {
        return;
      }

      analyticsService.track(INVOICES_DETAILD, analyticsService.createInvoicesDetailedPayload(invoice.invoiceNumber));

      const foundInvoice = this.invoices.find(localInvoice => localInvoice.id === invoice.id);

      foundInvoice.progress = 0;
      foundInvoice.isError = false;
      foundInvoice.isLoading = true;

      mediaService
        .getPrivateMediaBlob(getBaseUrlWithoutApi() + foundInvoice.pdfFile, {
          onDownloadProgress: ({ loaded, total }) => {
            foundInvoice.progress = parseInt(Math.round((loaded / total) * 100), 10);
          },
        })
        .then(blob => {
          download(foundInvoice.fileName, blob);
          foundInvoice.isLoading = false;
        })
        .catch(() => {
          foundInvoice.isError = true;
        });
    },

    // eslint-disable-next-line func-names
    debouceFetch: debounce(function() {
      this.getInvoices();
    }, 300),

    showModal(message) {
      this.modal.show = true;
      this.modal.message = message;
    },

    closeModal() {
      this.modal.show = false;
      this.modal.message = '';
    },

    formatInvoices(invoices) {
      return invoices.map(invoice => ({
        ...invoice,
        unitName: invoice.unit.parentUnit ? invoice.unit.parentUnitName : invoice.unit.name,
        roomName: invoice.unit.parentUnit ? invoice.unit.name : '-',
        invoiceDate: this.formatDate(invoice.invoiceDate),
        period: this.formatDate(invoice.period),
        dueDate: this.formatDate(invoice.dueDate),
        periodEnd: this.formatDate(invoice.periodEnd),
        paymentDate: this.formatDate(invoice.paymentDate),
        amount: separateThouthands(invoice.amount),
        isLoading: false,
        isError: false,
        progress: 0,
        fileName: decodeURI(invoice?.pdfFile?.split('/')?.pop()),
        vat: `${invoice.vat}%`,
      }));
    },

    formatReccurenceInvoice(invoices) {
      return invoices.map(invoice => ({
        ...invoice,
        unitName: invoice.unit.parentUnit ? invoice.unit.parentUnitName : invoice.unit.name,
        roomName: invoice.unit.parentUnit ? invoice.unit.name : '-',
        clientName: `${invoice.client?.firstName} ${invoice.client?.lastName}`,
        projectName: invoice.project.name,
        recurrencePeriod: this.$t(invoice.recurrencePeriod.name),
        invoiceDateFirst: this.formatDate(invoice.invoiceDateFirst),
        invoiceDateLast: this.formatDate(invoice.invoiceDateLast),
        fileName: decodeURI(invoice?.pdfFile?.split('/')?.pop()),
        isLoading: false,
        isError: false,
        progress: 0,
      }));
    },

    formatDate(date) {
      if (!date) return '';
      const noFormatDate = new Date(date);

      return format(noFormatDate, 'd L yyyy');
    },

    async getInvoices() {
      if (this.$options.cancelFetchInvoices) {
        this.$options.cancelFetchInvoices();
        await flushPromises();
      }

      this.tableLoading = true;
      this.metricIsLoading = true;
      const cancelSource = client.getCancelToken();
      this.$options.cancelFetchInvoices = cancelSource.cancel;

      try {
        const fetch = this.isEmployee ? this.getEmployeesInvoices : this.getClientsInvoices;

        const { count, results, summaryInfo, currency } = await fetch({ token: cancelSource.token });

        this.summaryInfo = { ...summaryInfo, ...currency };
        this.invoicesCount = count;
        this.invoices = this.isRecurrence ? this.formatReccurenceInvoice(results) : this.formatInvoices(results);
      } finally {
        this.$options.cancelFetchInvoice = null;
        this.tableLoading = false;
        this.isLoading = false;
        this.metricIsLoading = false;
      }
    },

    async getPaymentTypes() {
      if (this.isEmployee) {
        const cancelSource = client.getCancelToken();
        this.$options.cancelFetchInvoices = cancelSource.cancel;

        const { results } = await paymentsService.getPaymentTypes({
          config: {
            cancelToken: cancelSource.token,
          },
        });

        this.paymentTypes = results;
      }
    },

    getEmployeesInvoices({ token } = {}) {
      const getInvoices = this.isRecurrence
        ? paymentsService.getEmployeesRecurrenceInvoices
        : paymentsService.getEmployeesInvoices;

      return getInvoices({ ...this.queryParams, config: { cancelToken: token } });
    },

    getClientsInvoices() {
      return paymentsService.getClientsInvoices({
        limit: this.invoicesLimit,
        offset: this.invoicesLimit * (this.page - 1),
        unpaidOnly: this.isUnpaid,
        orderBy: this.orderBy,
        search: this.query,
      });
    },

    toggleUnpaid() {
      this.isUnpaid = !this.isUnpaid;
    },

    issueInvoice() {
      this.$router.push({ name: INVOICES_CREATE, query: { prevPage: this.page } });
    },

    issueRecurrenceInvoice() {
      this.$router.push({ name: INVOICES_RECURRENCE_CREATE, query: { prevPage: this.page } });
    },

    exportInvoices() {
      this.isLoading = true;

      analyticsService.track(EXPORT_INVOICES);
      try {
        if (this.isRecurrence) {
          paymentsService.downloadRecurringInvoices('invoices.xlsx');
        } else {
          paymentsService.downloadInvoices('invoices.xlsx', { ...this.normalizedFilters });
        }
      } finally {
        this.isLoading = false;
      }
    },

    async importInvoices(files) {
      const invoices = files[0];
      const status = await this.getImportStatus();

      if (status === IN_PROCESS) {
        this.showModal(this.$t('import.process_try_again'));
        return;
      }

      const importInvoices = this.isRecurrence
        ? paymentsService.importRecurringInvoices
        : paymentsService.importInvoices;

      importInvoices(invoices).finally(() => {
        analyticsService.track(IMPORT_INVOICES);
        this.fetchImportStatus();
      });
    },

    async deleteInvoice(invoice) {
      const needDelete = await this.$refs.deleteDialog.open();

      if (needDelete) {
        this.isLoading = true;

        const fetch = this.isRecurrence ? paymentsService.deleteRecurrenceInvoice : paymentsService.deleteInvoice;

        fetch(invoice.id)
          .then(() => {
            return this.getInvoices();
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },

    // eslint-disable-next-line func-names
    fetchImportStatus: retry(async function() {
      const uploadState = this.isRecurrence
        ? await paymentsService.importRecurringStatus()
        : await paymentsService.importStatus();

      this.importStatus = uploadState;

      if (uploadState.status === READY && !this.sendMessagesAfterMounted) {
        this.getInvoices();
        notificationService.success(this.$t('import.upload_completed_successfully'), 2000);
      }

      this.sendMessagesAfterMounted = false;

      return uploadState.status === IN_PROCESS;
    }),

    async getImportStatus() {
      const fetchStatus = this.isRecurrence ? paymentsService.importRecurringStatus : paymentsService.importStatus;

      const { status } = await fetchStatus();

      return status;
    },

    async savePaymentType(paymentType, comment, invoice) {
      const cancelSource = client.getCancelToken();
      const data = [];
      const { selectedInvoices } = this.invoiceHighlighting;

      if (selectedInvoices.length) {
        const invoiceIds = selectedInvoices
          .filter(invoiceItem => !(invoiceItem.status === 'paid' && invoiceItem.paymentTypeId == null))
          .map(invoiceItem => invoiceItem.id);

        if (invoiceIds.length < selectedInvoices.length) {
          this.openPaymentModal();
        }

        invoiceIds.forEach(id => {
          const obj = {
            invoice: id,
            paymentType,
            comment: comment ? comment.trim() : null,
          };
          data.push(obj);
        });
      } else {
        data.push({ invoice, paymentType, comment: comment ? comment.trim() : null });
      }

      if (data.length) {
        paymentsService
          .updatePaymentType({
            data,
            config: {
              cancelToken: cancelSource.token,
            },
          })
          .then(() => this.getInvoices());
      }
    },

    async updateComment({ comment, invoiceId } = {}) {
      paymentsService.updateInvoiceComment({ invoiceComment: comment, invoiceId }).then(() => {
        this.invoices = this.invoices.map(invoice =>
          invoiceId === invoice.id ? { ...invoice, invoiceComment: comment } : invoice
        );
      });
    },

    openPaymentModal() {
      this.paymentModal = true;
    },

    closePaymentModal() {
      this.paymentModal = false;
    },

    setLimit(value) {
      this.invoicesLimit = value;
      if (this.$route.query.page !== '1') {
        this.pageModel = 1;
      }

      saveStorageItem(INVOICE_ITEM_PAGE_COUNT, this.invoicesLimit);
      this.debouceFetch();
    },

    changeConfig(configs) {
      const confibObject = configs.reduce((acc, item) => {
        const { value, ...rest } = item;
        acc[value] = rest;
        return acc;
      }, {});
      this.invoiceTableConfigs = [...configs];
      saveStorageItem(INVOICE_TABLE_CONFIGS, JSON.stringify(confibObject));
    },
  },

  getServiceType,
  translateInvoiceDate,
  INVOICES_RECURRENCE_EDIT,
  cancelFetchInvoices: null,
};
</script>

<style lang="scss">
.invoice {
  height: 100%;

  &__missing-data {
    height: 100%;
  }

  &__loader-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 200px;
  }

  &__loader {
    color: $--primary-color;
  }

  &__row {
    position: relative;
  }
}
</style>
